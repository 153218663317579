<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">系统通道管理</div>
      <el-form :inline="true" size="mini">
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column label="通道编号" prop="channelNumber" width="100px"> </el-table-column>
        <el-table-column label="通道名称" prop="channelName"></el-table-column>
        <el-table-column label="通道别名" prop="channelAlias" width="130px"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" min-width="135px"></el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <ChannelMgAdd ref="channel-mg-add"></ChannelMgAdd>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes, jumper" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ChannelMgAdd from "./channel-mg-add.vue";
export default {
  components: { ChannelMgAdd },
  data() {
    return {
      p: {
        config: '',
        page: 1,
        limit: 10,
      },
      title: "添加黑名单信息",
      userDialogVisible: false,
      dataCount: 0,
      conditionsKey: 0,
      selectList: [],
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get",
      };
      this.sa.ajax(
        "/v1/channelmg/list",
        this.p,
        function (res) {
          this.dataList = res.data.records;
          this.dataCount = res.data.total;
        }.bind(this),
        defaultCfg
      );
    },
    update: function (data) {
      this.$refs["channel-mg-add"].open(data, 1);
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
