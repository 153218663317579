<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '添加黑名单信息' : '数据修改'" :visible.sync="isShow" width="500px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" :inline="true" label-width="150px">
          <el-form-item label="通道编号:">
            <el-input v-model="m.channelNumber"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="通道名称:">
            <el-input v-model="m.channelName"></el-input>
          </el-form-item>
          <el-form-item label="通道别名:">
            <el-input v-model="m.channelAlias"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      m: {
        channelNumber: "", // 从菜单配置文件里传递过来的参数
        channelName: "", // 从菜单配置文件里传递过来的参数
        channelAlias: "", // 从菜单配置文件里传递过来的参数
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data, id) {
      this.id = id;
      this.isShow = true;
      console.log(this.m);
      console.log(this.id);
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.channelNumber = data.channelNumber;
        this.m.channelName = data.channelName;
        this.m.channelAlias = data.channelAlias;
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 1) {
        // id == 0 为增加
        this.sa.ajax(
          "/v1/channelmg/update/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      Object.keys(this.m).forEach((key) => {
        this.m[key] = "";
      });
    },
  },
  created: function () {
  },
};
</script>
